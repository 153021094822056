import { useState, useEffect } from 'react'

const useForm = (formState) => {
  const [state, setState] = useState({ ...formState })
  const [errors, setErrors] = useState({
    required: {},
    invalid: {}
  })
  
  const onChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    setErrors({
      required: {},
      invalid: {}
    })
  }, [state]);

  return{
    state,
    errors,
    setErrors,
    setState,
    onChange
  }
}
export default useForm;