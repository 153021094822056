import React, { useEffect, useState } from 'react'
import { valid } from '../components/validation'
import useForm from '../components//useForm'
import { login } from "../actions/authActions"
import { connect } from "react-redux"
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import originAllLogo from '../assets/images/darkBig-logo.svg'
import { AiOutlineMail } from "react-icons/ai";
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { NotificationManager } from 'react-notifications'
import { FiEye, FiEyeOff } from 'react-icons/fi'

const SignInForm = props => {

  const { state, setState, errors, setErrors, onChange } = useForm({
    email: '',
    password: ''
  })
  const { email } = props
  const [typePassword, setTypePassword] = useState(true);
  const togglePassword = () => {
    setTypePassword(!typePassword)
  }

  useEffect(() => {
    email && setState({
      ...state, email
    })
  }, [email]);// eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = e => {
    e.preventDefault();
    if (!valid.isValidEmail(state.email)) {
      setErrors({
        ...errors,
        invalid: { email: true }
      })
      return
    }
    props.login(state)
  }

  return (
    <div className={'d-flex flex-row flex-wrap flex-md-nowrap h100vh signinCtr'}>
      <div className="d-flex flex-grow-1 align-items-center justify-content-center px-5 loginBg">
        <img src={originAllLogo} alt="logo" width="85%" />
      </div>
      <div className="d-flex flex-grow-1 align-items-center justify-content-center blackBg signin">
        <div className={"d-flex flex-column w-50 px-3"}>
          <h1 className={'login-title white'}>Log In</h1>
          <p className={'login-info white'}>Please enter your account information</p>
          <ValidatorForm
            noValidate
            onSubmit={e => handleSubmit(e)}
            onError={() =>
              NotificationManager.error("Please fill the required fields", '', 3000)
            }
          >
            <div className="form-group signin">
              <TextValidator
                className="form-control"
                name="email"
                required
                type="email"
                value={state.email || ''}
                placeholder="Email"
                onChange={onChange}
                variant="filled"
                validators={['required']}
                errorMessages={[]}
                error={errors.invalid.email}
                helperText={
                  errors.invalid.email &&
                  "E-mail is not valid"
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AiOutlineMail />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="form-group signin">
              <TextValidator
                className="form-control mb-3"
                required
                placeholder="Password"
                value={state.password || ''}
                type={typePassword ? "Password":"text"}
                name="password"
                variant="filled"
                onChange={onChange}
                validators={['required']}
                errorMessages={[]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {typePassword ? <FiEye onClick={togglePassword} className='cursor-pointer' /> : <FiEyeOff onClick={togglePassword} />}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="">
              <Button
                color="primary"
                className="loginButton text-white font-16"
                // disabled={loading}
                variant="contained"
                size="large"
                type="submit"
              >
                Log In
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
    </div>
  )
}

export default connect(null, {
  login
})(SignInForm);