export const valid = {
  isValidEmail: email => {
    const regEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regEx.test(email);
  },

  isPasswordValid: password => {
    if (password === '') return true;
    const passwordPattern = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z])(?=.{8,}).*$/;
    return password.match(passwordPattern);
  },

  matchPassword: (newPassword, passwordConfirmation) => {
    return passwordConfirmation === newPassword;
  },
};
